<template>
  <div>
    <!-- filtros dos feriados -->
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>Filtro</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="year"
                  :items="years"
                  label="Ano"
                  outlined
                  @change="filter"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  :items="states"
                  label="Estado"
                  outlined
                  @change="setState"
                  item-text="estado"
                  item-value="id"
                  clearable
                  v-model="stateId"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  :items="cities"
                  label="Cidade"
                  outlined
                  @change="setCitie"
                  item-text="municipio"
                  item-value="id"
                  clearable
                  v-model="cityId"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-card outlined>
              <v-card-subtitle> Legenda dos feriados </v-card-subtitle>
              <v-card-text>
                <div class="d-flex justify-space-around">
                  <span class="nacional"
                    >Feriados móveis e nacionais = {{ Fnacionais }}</span
                  >
                  <span class="estadual"
                    >Feriados estaduais = {{ Festaduais }}</span
                  >
                  <span class="municipal"
                    >Feriados municipais = {{ Fmunicipais }}</span
                  >
                  <span style="margin-top: 15px"
                    >Feriados totais =
                    {{ Fmunicipais + Festaduais + Fnacionais }}</span
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- calendarios -->
    <v-row>
      <v-col cols="4" v-for="(m, idx) in months" :key="idx">
        <v-card>
          <v-card-title>
            <h2>{{ m.monthYear.toUpperCase() }}</h2>
          </v-card-title>
          <v-card-subtitle>
            <div class="calendar-header">
              <div v-for="day in daysOfWeek" :key="day">
                {{ day.toUpperCase() }}
              </div>
            </div>
          </v-card-subtitle>
          <v-card-text>
            <div class="calendar-body">
              <div
                v-for="(day, idxd) in m.daysInMonth"
                :key="idxd"
                :class="{ highlight: day.isHoliday }"
                @click="openDialog({ dia: day.date, mes: m.month })"
              >
                <div v-if="day.date" class="pointer">
                  {{ day.date }}
                  <br />
                </div>
              </div>
            </div>
            <!-- lista de feriados -->
            <div v-for="(h, hidx) in m.holidays" :key="hidx">
              <span :class="getType(h)">
                {{ h.dia }} - {{ h.name }}
                <span v-if="h.id > 0">
                  <v-btn icon small @click="edtHoliday(h)">
                    <v-icon small color="black">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteHoliday(h)">
                    <v-icon small color="black">mdi-trash-can</v-icon>
                  </v-btn>
                </span>
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- card de cadastro -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Cadastrar Feriado {{ selected.dia.toString().padStart(2, 0) }} /
          {{ selected.mes.toString().padStart(2, 0) }}
        </v-card-title>
        <v-card-text>
          <v-form ref="formFeriado" autocomplete="off">
            <v-row>
              <v-col>
                <v-text-field
                  label="Nome do feriado"
                  outlined
                  v-model="selected.name"
                  :rules="requiredField"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="states"
                  label="Estado do feriado"
                  outlined
                  @change="setState"
                  item-text="estado"
                  item-value="id"
                  v-model="selected.estadoId"
                  autocomplete="null"
                  :rules="user.level != 1 ? requiredField : []"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="cities"
                  label="Cidade do feriado"
                  outlined
                  @change="setCitie"
                  item-text="municipio"
                  item-value="id"
                  v-model="selected.cidadeId"
                  autocomplete="null"
                  :rules="user.level != 1 ? requiredField : []"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="saveHoliday"> Adicionar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredField } from "@/Utils/InputValidation";
import Url from "@/Utils/Url";
export default {
  name: "Feriados",
  data() {
    return {
      loading: false,
      index: 0,
      daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      holidays: [],
      months: [],
      year: 2024,
      years: [],
      states: [],
      state: {},
      stateId: null,
      cities: [],
      city: {},
      cityId: null,
      dialog: false,
      selected: {
        dia: 0,
        mes: 0,
        cidadeId: null,
        estadoId: null,
      },
      Fnacionais: 0,
      Festaduais: 0,
      Fmunicipais: 0,
      requiredField,
      user: this.$store.getters.getUsuario,
    };
  },
  methods: {
    filter() {
      this.generateCalendars();
    },
    setState(state) {
      this.state = this.states.find((s) => s.id === state);
      if (this.state) {
        this.getCities(this.state.uf);
        this.stateId = this.state.id;
        this.selected.estadoId = this.state.id;
      } else {
        this.city = {};
        this.cityId = null;
        this.cities = [];
      }
      this.getHolidays();
    },
    setCitie(citie) {
      this.city = this.cities.find((s) => s.id === citie);
      if (this.city) {
        this.cityId = this.city.id;
        this.selected.cidadeId = this.city.id;
      }
      this.getHolidays();
    },
    buildCalendars(month) {
      const date = new Date(`${this.year}-${month}-01:00:00:00`);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const daysInMonthy = lastDay.getDate();
      const startDay = firstDay.getDay(); // 0 (Domingo) - 6 (Sábado)

      let monthYear = date.toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "long",
      });

      let daysInMonth = [];

      // Preenche os dias vazios antes do primeiro dia do mês
      for (let i = 0; i < startDay; i++) {
        daysInMonth.push({ date: "", isHoliday: false });
      }

      let holidays = this.holidays.filter((h) => h.mes == month) || [];

      // Preenche os dias do mês
      for (let day = 1; day <= daysInMonthy; day++) {
        const isHoliday = this.holidays.some(
          (h) => h.dia === day && h.mes === month
        );
        daysInMonth.push({
          date: day,
          isHoliday,
        });
      }

      return {
        monthYear,
        daysInMonth,
        holidays,
        month,
      };
    },
    generateCalendars() {
      this.loading = true;
      let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      this.months = [];
      for (let month of months) {
        this.months.push(this.buildCalendars(month));
      }
      this.loading = false;
    },
    async getStates() {
      this.loading = true;
      try {
        let resp = await this.$http.get("estados");
        this.states = resp.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getCities(uf) {
      this.loading = true;
      try {
        let url = "cidades";
        if (uf) url += `?uf=${uf}`;
        let resp = await this.$http.get(url);
        this.cities = resp.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getHolidays() {
      this.Festaduais = 0;
      this.Fnacionais = 0;
      this.Fmunicipais = 0;
      this.loading = true;
      try {
        let url = `feriados${Url.genQueryFromObj({
          year: this.year,
          estadoId: this.state ? this.state.id : undefined,
          cidadeId: this.city ? this.city.id : undefined,
        })}`;
        let resp = await this.$http.get(url);
        this.holidays = resp.data;
        this.holidays.forEach((h) => {
          if (h.estadoId && h.cidadeId) {
            this.Fmunicipais++;
          }
          if (!h.estadoId && !h.cidadeId) {
            this.Fnacionais++;
          }

          if (h.estadoId) {
            this.Festaduais++;
          }
        });
        this.loading = false;
        this.generateCalendars();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    openDialog(selected) {
      this.dialog = true;
      this.selected = selected;
    },
    saveHoliday() {
      if (!this.$refs.formFeriado.validate()) return;
      let methods = this.selected.id ? "put" : "post";
      let url = this.selected.id ? `feriados/${this.selected.id}` : "feriados";
      this.$http[methods](url, this.selected).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSuccessSnack", "Feriado salvo com sucesso");
        this.getHolidays();
        this.dialog = false;
      });
    },
    deleteHoliday(item) {
      let url = item.id ? `feriados/${item.id}` : "feriados";
      this.$http.delete(url).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showSuccessSnack",
          "Feriado deletado com sucesso"
        );
        this.getHolidays();
        this.dialog = false;
      });
    },
    edtHoliday(item) {
      this.selected = item;
      this.dialog = true;
    },
    getType(item) {
      if (item.estadoId && item.cidadeId) {
        return "municipal";
      }
      if (item.estadoId) {
        return "estadual";
      }
      return "nacional";
    },
  },
  created() {
    // pega a data atual e seta o ano na variavel
    let now = new Date();
    this.year = now.getFullYear();
    // gera os calendarios
    this.generateCalendars();
    this.getStates();
    this.getHolidays();
    // gera os anos para o filtro
    for (let i = 0; i < 11; i++) {
      this.years.push(this.year + i);
    }
  },
};
</script>


<style scoped>
.calendar-header,
.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}
.calendar-header div,
.calendar-body div {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
.highlight {
  background-color: #676767;
}
.nacional {
  margin-top: 15px;
  background-color: red;
  padding-left: 5px;
  padding-right: 5px;
  color: black;
}
.estadual {
  margin-top: 15px;
  background-color: rgb(0, 26, 255);
  padding-left: 5px;
  padding-right: 5px;
  color: black;
}
.municipal {
  margin-top: 15px;
  background-color: rgb(89, 253, 0);
  padding-left: 5px;
  padding-right: 5px;
  color: black;
}
.pointer {
  cursor: pointer;
}
</style>